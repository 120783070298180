/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap'); */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import url('https://fonts.googleapis.com/css2?family=Geist:wght@100..900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    --font-family: 'Geist', 'sans-serif';
    --background-color: #ffffff;
    --background-hover-color: #f1f1f1;
    --scrollbar-thumb-color: #c1c1c1;
    --scrollbar-thumb-hover-color: #a1a1a1;
    --gradient-bg-1: #eeeeee;
    --gradient-bg-2: #f5f5f5;
    --menu-gradient-1: #d1d1d1;
    --menu-gradient-2: #e2e2e2;
    --header-btn-hover-color: #d0d0d0;
    --light-color: #000000;
    --dark-color: #ffffff;
    --menu-subcategory-item-color: #4a4a4a;
    --menu-collapse-bg: #dbdbdb;
    /* TC */
    --tc-table-border-color: #d1d5db;
    --tc-table-bg-hover-color: #f3f4f6;
    --tc-btn-bg-color: #e5e7eb;
    --tc-btn-hover-bg-color: #d1d5db;
    --primary-color: #3b82f6;
    --primary-color-hover: #2563eb;
}

.app-dark-theme {
    --font-family: 'Inter Tight', 'sans-serif';
    --background-color: #171e28;
    --background-hover-color: #0a0c10;
    --scrollbar-thumb-color: #3e4a5b;
    --scrollbar-thumb-hover-color: #4f5767;
    --gradient-bg-1: #090c11;
    --gradient-bg-2: #19202a;
    --menu-gradient-1: #293141;
    --menu-gradient-2: #3d4a61;
    --header-btn-hover-color: #283141;
    --light-color: #ffffff;
    --dark-color: #000000;
    --menu-subcategory-item-color: #bebec5;
    --menu-collapse-bg: #0a0c10;
    /* TC */
    --tc-table-border-color: #373e45;
    --tc-table-bg-hover-color: #0d111a;
    --tc-btn-bg-color: #31394b;
    --tc-btn-hover-bg-color: #3e465a;
    --primary-color: #3b82f6;
    --primary-color-hover: #2563eb;

    transition: all 0.3s ease-in-out;
}

* {
    font-size: 14px;
}

.dark-gradient-bg {
    background: linear-gradient(140deg, var(--gradient-bg-1) 0%, var(--gradient-bg-2) 100%);
}

body {
    font-family: var(--font-family);
    font-feature-settings: "cv02", "cv03", "cv04", "cv11";
    font-size: 14px;
    font-weight: 500;
    background-color: var(--background-color);
    /* overflow-y: hidden;
    overflow-x: hidden; */
}

.main-content {
    min-width: 100%;
    min-height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
}

.sidebar {
    /* min-width: 280px; */
    width: 0px;
    height: 100vh;
    overflow-y: auto;
    background: linear-gradient(140deg, var(--gradient-bg-1) 0%, var(--gradient-bg-2) 100%);
}

.collapsed-sidebar {
    min-width: 0px;
}

.p-tree {
    border: none;
    background-color: transparent;
    padding: 0;
}

.p-tree-container ol,
ul {
    padding-left: 0;
}

.page {
    /* min-width: calc(100% - 280px); */
    min-width: 100%;
    height: 100vh;
    overflow-y: auto;
}

.mobile-sidebar {
    background: linear-gradient(140deg, var(--gradient-bg-1) 0%, var(--gradient-bg-2) 100%);
    padding: 0px 5px;
    max-width: 280px;
}

.menubar-profile {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.menubar-profile:hover {
    border-radius: 5px;
    background: linear-gradient(140deg, var(--menu-gradient-1) 50%, var(--menu-gradient-2) 100%);
}

.header {
    background: linear-gradient(140deg, var(--gradient-bg-1) 0%, var(--gradient-bg-2) 100%);
}

.header-btn {
    border: none;
}

.header-btn:hover {
    background-color: var(--header-btn-hover-color);
}

.menu-category-btn {
    width: 100%;
    padding: 8px 8px;
    padding-left: 12px;
    margin: 5px 0px;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    background-color: transparent;
    border: none;
}

.menu-category-btn-hovered {
    background: linear-gradient(140deg, var(--menu-gradient-1) 50%, var(--menu-gradient-2) 100%);
}

.menu-category-btn:hover {
    background: linear-gradient(140deg, var(--menu-gradient-1) 50%, var(--menu-gradient-2) 100%);
}

.menu-subcategory-item {
    width: 100%;
    padding: 8px 8px;
    padding-left: 20px;
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    text-decoration: none;
    background-color: transparent;
    border: none;
    border-radius: 0;
    color: var(--menu-subcategory-item-color);
    transition: all 0.3s ease-in-out;
}

.menu-subcategory-item:hover {
    padding-left: 23px;
    color: var(--white-color);
    font-weight: 500;
}

.menu-collapse {
    background-color: var(--menu-collapse-bg);
    border: none;
    border-radius: 5px;
}

/* first and last menu-subcategory-item in collapse should have rounded corners in top left and right */
.menu-collapse .menu-subcategory-item:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.menu-collapse .menu-subcategory-item:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dropdown-menu {
    font-size: 13px;
}

.dropdown-toggle-no-after::after {
    display: none;
}

.dropdown-item {
    font-size: 13px;
    border-radius: 5px;
    padding: 7px 12px;
}

.p-dropdown-items {
    padding-left: 0;
}

.btn {
    font-size: 13px;
    font-weight: 600;
    border-radius: 5px;
}

.btn-info {
    background-color: var(--primary-color);
    color: var(--dark-color);
    border-color: var(--primary-color);
}

.btn-info:hover,
.btn-info:disabled,
.btn-info:focus-visible,
.btn.btn-info:first-child:active {
    background-color: var(--primary-color-hover);
    color: var(--dark-color);
    border-color: var(--primary-color-hover);
}

.btn-transparent {
    background-color: transparent;
}

.btn-secondary:hover {
    background-color: #f1f1f1;
    color: var(--dark-color);
    border: none;
}

.form-label {
    font-size: 13px;
    font-weight: 500;
}

.form-control {
    font-weight: 500;
    font-size: 13px;
}

.p-component {
    font-size: 13px;
}

.p-inputtext {
    padding: 0.5rem 0.5rem;
    font-size: inherit;
    font-weight: 500;
}

.p-paginator .p-dropdown {
    height: auto;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0.40rem 1.25rem;
}

.p-inputswitch {
    /* width: 3rem;
    height: 1.5rem; */
}

.p-card .p-card-content {
    padding: 0;
}

.react-datepicker {
    font-family: var(--font-family);
    border: 1px solid #ced4da;
}

.project-card {
    transition: all 0.3s ease-in-out;
}

.project-card:hover {
    background: linear-gradient(140deg, var(--menu-gradient-1)20 50%, var(--menu-gradient-2)50 100%);
}

.p-datatable-table th,
.p-datatable-table td {
    font-size: 12px;
}

.p-datatable-table th {
    /* background: linear-gradient(140deg, var(--gradient-bg-1) 0%, var(--gradient-bg-2) 100%); */
    background-color: var(--gradient-bg-1);
}

.p-datatable-header {
    padding: 10px 0px;
}

.p-menuitem-link {
    color: var(--white-color);
    text-decoration: none;
}

.text-info{
    color: var(--primary-color) !important;
}

.text-bg {
    background-color: var(--gradient-bg-2);
    color: var(--white-color);
}

.view-project-alert-item {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.view-project-alert-item:hover {
    background-color: var(--gradient-bg-2);
}

/* SCROLL BAR CSS */
/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--background-hover-color);
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-color)
}

/* .sidebar should be hide in mobile devices and .page width needs to be 100% */
@media (max-width: 768px) {
    .sidebar {
        display: none;
    }

    .page {
        min-width: 100%;
    }

    .mobile-sidebar {
        max-width: 75%;
    }
}
.tc-table .p-datatable-table th,
.tc-table .p-datatable-table td {
  border: 1px solid var(--tc-table-border-color);
  /* background-color: #0d111a; */
}

.tc-table .p-datatable-table .p-datatable-thead tr th {
  font-size: 12px;
}

.tc-table .p-datatable.p-datatable-selectable .p-datatable-tbody>tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background-color: var(--tc-table-bg-hover-color);
}

.tc-table .p-datatable .p-datatable-tbody>tr>td {
  padding: 0px;
}

.tc-table .p-datatable .p-datatable-tbody>tr>td {

  .p-inputtext,
  .p-dropdown {
    border-radius: 0px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
}

.tc-table .p-datatable .p-datatable-thead>tr>th {
  padding: 10px;
}

.tc-table .p-column-title {
  font-size: 12px;
}

.td-overlay-parent {
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  transition: all 0.1s ease-in-out;
}

.td-overlay-parent:hover {
  background-color: var(--tc-table-bg-hover-color);
}

.tc-table .p-datatable .p-column-resizer {
  width: 3px;
  transition: all 0.1s ease-in-out;
}

.tc-table .p-datatable .p-column-resizer:hover {
  width: 5px;
  background-color: #366ce8;
}

.tc-table .p-inputtext {
  padding: 0.2rem 0.5rem;
}

.tc-control-panel .p-inputtext {
  /* padding: 0.2rem 0.5rem; */
}

.status-div {
  background-color: var(--tc-table-bg-hover-color);
}

.more-td-btn {
  border: none;
  border-radius: 5px;
}

.more-td-btn:hover {
  background-color: var(--tc-btn-bg-color);
}

.tc-btn {
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.tc-dark-btn {
  background-color: var(--tc-btn-bg-color);
  color: #fff;
  border: 1px solid var(--tc-btn-hover-bg-color);
}

.tc-dark-btn:hover {
  background-color: var(--tc-btn-hover-bg-color);
}

.tc-dark-btn:active {
  background-color: var(--tc-btn-hover-bg-color);
}

.tc-success-btn {
  background-color: #00b300;
  color: #fff;
  border: 1px solid #00b300;
}

.tc-success-btn:hover {
  color: #fff;
  background-color: #00cc0080;
}

.tc-success-btn:active {
  color: #fff;
  background-color: #00cc0080;
}

/* .p-overlaypanel-content {
  padding: 7px 5px;
} */